// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-animal-healing-js": () => import("./../src/pages/animal-healing.js" /* webpackChunkName: "component---src-pages-animal-healing-js" */),
  "component---src-pages-booking-js": () => import("./../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-booking-thanks-js": () => import("./../src/pages/booking-thanks.js" /* webpackChunkName: "component---src-pages-booking-thanks-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-psychic-spiritual-readings-js": () => import("./../src/pages/psychic-spiritual-readings.js" /* webpackChunkName: "component---src-pages-psychic-spiritual-readings-js" */),
  "component---src-pages-reiki-attunement-js": () => import("./../src/pages/reiki-attunement.js" /* webpackChunkName: "component---src-pages-reiki-attunement-js" */),
  "component---src-pages-reiki-healing-js": () => import("./../src/pages/reiki-healing.js" /* webpackChunkName: "component---src-pages-reiki-healing-js" */),
  "component---src-pages-spiritual-assessments-js": () => import("./../src/pages/spiritual-assessments.js" /* webpackChunkName: "component---src-pages-spiritual-assessments-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-workshops-js": () => import("./../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

